@import "assets/scss/common";

.main {
  min-height: 100vh;
  padding-top: 20px;
  @include desktop{
    padding-top: 60px;
  }
}

.cardWrapper {
  margin: 0 auto;
  max-width: 1000px;
  h1 {
    padding-left: 24px;
    color: #fff;
    margin-bottom: 6px;
  }
}
.card {
  box-shadow: 0 12px 18px rgba(23, 27, 30, 0.15);
  border-radius: 10px;
  padding: 24px 0;
  background: #fff;
}
.cardContent{
  padding: 0 32px;
  h2{
    font-size: 1.3rem;
    font-weight: bold;
    @include desktop{
      font-size: 1.3rem;
    }
  }
}
.danger{
  color: $error;
}
.specialties {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  @include desktop {
    margin: 0 60px;
  }
}
.separator {
  width: 100%;
  font-size: 1.5rem;
  color: #828282;
  margin: 12px 0;
  display: flex;
  align-items: center;
  text-align: center;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 2px solid #e0e0e0;
  }
  &::before {
    margin-right: 0.25em;
  }
  &::after {
    margin-left: 0.25em;
  }
}
.actions {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  & *{
    max-width: 100%;
  }
  button {
    width: 300px;
    max-width: 100%;
    @include desktop {
      width: 300px;
    }
  }
}

.button {
  margin: 0 6pt 6pt 0 !important;
}

.wrap {
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
}
