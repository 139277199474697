@import "assets/scss/common";

$transition-duration: 2s;
$path-length: 157px; // Retrieved using SVG's getTotalLength()

.root{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.indicator {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2);
  svg{
    width: 60px;
    height: 60px;
    polyline {
      fill: none;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      &.back {
        stroke: rgba($primary,.3);
      }
      &.front {
        stroke: $primary;
        stroke-dasharray: 12, 36;
        stroke-dashoffset: 48;
        animation: dash 1s linear infinite;
      }
    }

  }
}

@-moz-keyframes dash {
  62.5% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash {
  62.5% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-o-keyframes dash {
  62.5% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  62.5% {
    opacity: 0;
  }
  to {
    stroke-dashoffset: 0;
  }
}
