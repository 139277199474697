@import "assets/scss/common";

.primary {
  fill: $illus-primary-default;
}

.secondary {
  fill: $illus-secondary;
}

.accentAlt {
  fill: $illus-alternative-accent;
}

.accent {
  fill: $illu-accent;
}

.alt {
  fill: $illus-alternative;
}
.alternative {
  .primary {
    fill: $illus-alternative;
  }

  .accent {
    fill: $illus-alternative-accent;
  }
}

.resize {
  height: auto;
}
