@import "assets/scss/common";

.root{
  user-select: none;
  width: 1em;
  height:  1em;
  display: inline-block;
  flex-shrink: 0;
  font-size: 1.6rem;
  fill: $text;

}


:global(.linealIconHoverBackground) path{
  fill: #F2F1FC;
  opacity: 1;
  transition: opacity ease-in-out 200ms;
}

