@import "assets/scss/common";


.cta{
  background: $primary-gradient;
  color: #fff;
  padding: 48pt 0;
  text-align: center;
  .title{
    color: inherit;
  }
  .sub{
    opacity: 0.85;
    max-width: 58ch;
    margin: 16pt auto 24pt auto;


    &Center{
      text-align: center;
    }
  }

}

.reverse{
  background: $primary-gradient-reverse;
}

.plain{
  background: $primary;
}

.secondary{
  background: $secondary;
}
.noBg{
  background: none;
  color: $text;
}
