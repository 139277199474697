// Colors
//$primary-img:#38b9ad;

$primary: #31b98e;
$secondary: #3ba4b2;
//$primary: #6C63FF;
//$secondary: #4641FF;
$accent: #393892;
$accent-alt: #514f9a;
$accent-dark: #27265e;

$highlight: #ffcf90;

$primary-dark: darken($primary, 8%);
$text: #666;
$text-dark: #47546d;
$text-alt: #e6e7ea;
$background: #ffffff;
$background-alt: #f7f7f7;
$background-dark: #47546d;
$background-dark-alt: #576786;
$line: #eee;
$line-dark: #c4c4c4;
$success: $accent;
$error: #e53e5c;

$grad-initial: #38b989;
$primary-gradient: linear-gradient(to right, $grad-initial, $secondary);
$primary-gradient-reverse: linear-gradient(to left, $grad-initial, $secondary);
$primary-gradient-vertical: linear-gradient(
  to bottom,
  $grad-initial,
  $secondary
);
$accent-gradient: linear-gradient(to right, #495791, #303068);
$warn-gradient: linear-gradient(to top, #FF9F8A, #EC5773);

$modal-gradient: linear-gradient(
  to bottom,
  $accent,
  darken($background-dark, 8%)
);

// Typography
$google-font: "Questrial";
$font: "#{$google-font}", sans-serif;
$font-size: 15px;
$line-height: 1.7;
$light: 300;
$normal: 400;
$bold: 700;

// Other variables
$radius: 30px;

// Containers
$containerWidth: 88%; // Percentage of screen width
$containerMax: 1240px; // Largest fixed width
$text-container: 640px; // Limit texts for readability

// Media queries
$tablet-width: 600px !default; // Tablets
$desktop-width: 992px !default; // Desktops & Laptops
$huge-width: 1400px !default; // Large Desktops

/* Components */

// Navigation bar
$navbar-color: $primary;
$navbar-height: 60px; // Height for navigation globally
$navbar-height-extended: 70px; // Extended height for navigation => Used on landing

// Hero
$hero-color: $background;
$hero-shadow: 0 4px 11px rgba(124, 146, 169, 0.5);
$hero-color-opacity: 0.93;
$hero-mask-opacity: 0.66;
$hero-down-size: 1rem;

// Illustrations
//$illus-primary-default : #393892;
$illus-primary-default: $accent;
//$illus-alternative: $primary;
$illus-alternative: #009688;
$illus-secondary: #3f3d56;
$illu-accent: #ef5095;
$illus-alternative-accent: $accent-alt;

// --------------- SHADOWS ---------------
$heavy-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
  0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
$medium-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
$light-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
