@import "assets/scss/common";

.root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content{
  padding-top: $navbar-height;
  flex-grow: 1;
}
