@import "assets/scss/common";

.colourBurn {
  mix-blend-mode: color-burn;
}

.cls-1 {
  fill: url(#linear-gradient);
}
.cls-2 {
  fill: url(#linear-gradient-2);
}
.cls-3 {
  fill: url(#linear-gradient-3);
}
.cls-4 {
  fill: url(#linear-gradient-4);
}
.cls-5 {
  fill: url(#linear-gradient-5);
}
.cls-6 {
  fill: url(#linear-gradient-6);
}
.cls-7 {
  fill: url(#linear-gradient-7);
}
.cls-8 {
  fill: url(#linear-gradient-8);
}
.cls-9 {
  fill: url(#linear-gradient-9);
}
.cls-10 {
  fill: url(#linear-gradient-10);
}
.cls-11 {
  fill: url(#linear-gradient-11);
}
.cls-12 {
  fill: url(#linear-gradient-12);
}
.cls-13 {
  fill: url(#linear-gradient-13);
}
.cls-14 {
  fill: url(#linear-gradient-14);
}
.cls-15 {
  fill: url(#linear-gradient-15);
}
.cls-16 {
  fill: url(#linear-gradient-16);
}
.cls-17 {
  fill: url(#linear-gradient-17);
}
.cls-18 {
  fill: url(#linear-gradient-18);
}
.cls-19 {
  fill: url(#linear-gradient-19);
}
.cls-20 {
  fill: url(#linear-gradient-20);
}
.cls-21 {
  fill: url(#linear-gradient-21);
}
.cls-22 {
  fill: url(#linear-gradient-22);
}
.cls-23 {
  fill: url(#linear-gradient-23);
}
.cls-24 {
  fill: url(#linear-gradient-24);
}
.cls-25 {
  fill: url(#linear-gradient-25);
}
.cls-26 {
  fill: url(#linear-gradient-26);
}
.cls-27 {
  fill: url(#linear-gradient-27);
}
.cls-28 {
  fill: url(#linear-gradient-28);
}
.cls-29 {
  fill: url(#linear-gradient-29);
}
