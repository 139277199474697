@import "assets/scss/common";
.content{
  h1{
    padding-top: 20px;
  }
  h2{
    color: $primary;
    border-bottom: 1px solid $primary-dark;
    font-size: 1.4em;
    @include desktop{
      font-size: 1.728em;
    }
  }
  a{
    color: $accent;
  }
}
