@import "assets/scss/common";

.footer{
  background-color: $background;
  padding: 36pt 0;

  .inner{
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include tablet{
      flex-direction: row;
    }
  }

  .textLogo{
    flex-shrink: 0;
    padding-right: 16pt;
    display: block;
    cursor: pointer;
    color: $primary;
    font-size: 1.225rem;
    min-width: 33%;
    margin-bottom: 1.5rem;

    @include tablet{
      margin-bottom: 0;
    }

    @include desktop{
      min-width: 25%;
      font-size: 1.325rem;
    }
  }

  .data{
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    padding-top: 0.25rem;
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 0.9rem;

    @include desktop{
      flex-wrap: nowrap;
      flex-direction: row;
    }

    .dataItem{
      padding-bottom: 0.5rem;
      display: inline-block;
      margin-bottom: 8pt;
    }

    .row{
      margin-bottom: 8pt;
    }

  }

  .link{
    color: $primary;
    border-bottom: 1px dotted $primary;
    padding-bottom: 1pt;
    transition: 0.7s;

    @include desktop{
      &:hover{
        color: #fafafa;
        border-color: #fafafa;
      }
    }
  }


}

.disclaimer{
  color: #d9d9d9;
  font-size: 0.8rem;
  font-style: italic;
  line-height: 0.95rem;
  padding-bottom: 16px;
}



.footer__row{
  margin-bottom: 8pt;
}

/*--- Modifiers---*/
.footerDark{
  background-color: $background-dark;

  .textLogo{
    color: #fff;
  }

  .inner{
    color: #d9d9d9;
  }

  .link{
    color: #d9d9d9;
    border-color: #d9d9d9;

    @include desktop{
      &:hover{
        color: #fff;
        border-color: #fff;
      }
    }
  }
}
