@import "assets/scss/common";
//.buttonx {
//  border: 0;
//  text-decoration: none;
//  cursor: pointer;
//  user-select: none;
//  outline: none;
//  position: relative;
//
//  display: inline-flex;
//  align-items: center;
//  vertical-align: middle;
//  justify-content: center;
//
//  font-size: 0.875rem;
//  min-width: 64px;
//  box-sizing: border-box;
//  min-height: 36px;
//  font-weight: 500;
//  text-transform: uppercase;
//  box-shadow: $light-shadow;
//  margin: 4px;
//  border-radius: 4px;
//  padding: 0 18px;
//  transition: background-color 250ms ease-in-out 0ms,
//    box-shadow 250ms ease-in-out 0ms, border 250ms ease-in-out 0ms;
//  color: $primary-hover;
//  background-color: #ffffff;
//
//  &:hover {
//    background-color: $background;
//  }
//
//  &:active {
//    box-shadow: $heavy-shadow;
//  }
//  &.solid {
//    color: #ffffff;
//    border-color: $primary;
//    background-color: $primary;
//    &:hover {
//      background-color: $primary-hover;
//    }
//  }
//
//  &.rounded {
//    border-radius: 36px;
//    padding: 0 24px;
//  }
//  &.outline {
//    background-color: #fff;
//    border: 2px solid $primary;
//
//    &:hover {
//      color: #fff;
//      border-color: $primary;
//      background-color: $primary;
//    }
//  }
//}

@mixin inverse ($c){

}

.button{
  display: inline-block;
  padding: 6pt 20pt;
  line-height: $line-height;
  border: 1px solid $primary;
  color: $primary;
  font-weight: $normal;
  transition: 0.7s;
  white-space: nowrap;
  cursor: pointer;
  background-color: transparent;
  border-radius: $radius;
  margin-bottom: 1.3em;
  text-align: center;
  text-decoration: none;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover,&:focus{
    outline: 0;
  }

  @include desktop{
    &:hover{
      border-color: $primary-dark;
      color: $primary-dark;
    }
  }

  &.primary{
    background-color: $primary;
    border-color: $primary;
    color: #fff;

    @include desktop{
      &:hover{
        background-color: $primary-dark;
        border-color: $primary-dark;
        color: #fff;
      }
    }
  }

  &.accent{
    background-color: $accent;
    border-color: $accent;
    color: #fff;

    @include desktop{
      &:hover{
        background-color: darken($accent,4%);
        border-color: darken($accent,4%);
        color: #fff;
      }
    }
  }

  &.inverse{
    border-color: #fff;
    color: #fff;

    @include desktop{
      &:hover{
        border-color: #e3e3e3;
        color: #e3e3e3;
      }
    }
  }

  &.warn{
    background-color: $error;
    border-color: $error;
    color: #fff;

    @include desktop{
      &:hover{
        background-color: darken($error,4%);
        border-color: darken($error,4%);
        color: #fff;
      }
    }
  }

  &:disabled{
    opacity: 0.25;
    cursor: default;
    pointer-events:none;
  }
  // Utility classes
  &Fixed{
    width: 160px;
  }
  &Fixed2{
    width: 220px;
  }
}

