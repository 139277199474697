@import "assets/scss/common";

.navbar {
  position: fixed;
  z-index: 100;
  width: 100%;
  //background: $accent-gradient;
  //transition: 0.7s;

  &NoFixed {
    position: relative;
    z-index: 3;
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $navbar-height;
  color: #fff;
  transition: 0.7s;
}

// Content
.logo {
  font-size: 1.425rem;
  transition: none;
  color: #f9e0be;
  text-decoration: none;
  display: flex;
  align-items: center;
  svg {
    height: 38px;
    max-width: 50px;
    path {
      //fill: white;
    }
  }

  .text {
    padding-left: 10px;
    display: inline-block;
    //color: #FFF293;
  }

  @include desktop {
    .text {
    }
    //display: block;
    svg,
    path {
      //fill: #fff;
      //opacity: 0.9;
    }
  }
}

.menu {
  display: none;

  &NoMob {
    display: block;
  }

  @include desktop {
    display: block;
  }

  li {
    margin-left: 4pt;
    backface-visibility: hidden;
    display: inline-block;

    @include desktop {
      margin-left: 16pt;
    }
  }

  a {
    color: #fff;
    @include desktop {
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }

      &:after {
        display: block;
        content: "";
        border-bottom: solid 1px #fff;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }

      &:hover:after {
        transform: scaleX(1);
      }
    }
  }
}

.menuMob {
  display: block;

  &NoMob {
    display: none;
  }

  @include desktop {
    display: none;
  }
}

/*--- Extended and transparent version ---*/
.bg {
  opacity: 1;
  background: $accent-gradient;
  width: 100%;
  transition: 0.7s;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.extended {
  background: transparent;

  .inner {
    height: $navbar-height;
    color: $hero-color; // Since this is transparent whatever color is used in the hero, fits here
  }
  &:not(.opened){
    .bg {
      opacity: 0;
    }
  }

}

/*! responsive-nav.js 1.0.39 by @viljamis */

.mobile {
  clip: rect(0 0 0 0);
  max-height: 0;
  //position: absolute;
  display: block;
  overflow: hidden;
  zoom: 1;
  position: relative;
  transition: max-height 300ms ease 0s;

  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: block;
    list-style: none;
    background: $accent-gradient;
    color: #fff;
  }

  li {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding: 1em 0;
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    a{
      color: #f9e0be;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &.opened {
    max-height: 9999px;
  }
}

.nav-toggle {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@include desktop {
  .mobile {
    display: none;
  }
  .mobile.closed {
    max-height: none;
  }
  .nav-toggle {
    display: none;
  }
}

.toggle svg {
  height: 1.2em;
  width: 1.2em;
  vertical-align: middle;
  color: $highlight;
}
