@import "assets/scss/common";

.root{
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  @include desktop{
    flex-direction: row;
  }
}

.logo{
  position: absolute;
  top: 10pt;
  left: 10pt;
  svg{
    height: 35px;
    width: auto;
    path{
      fill: $primary;
    }
  }

  @include tablet{
    svg{
      height:45px;
      width: auto;
    }

  }

  @include desktop{
    top: 10pt;
    left: 10pt;
    svg path {
      fill: white;
    }
  }
}

.media{
  padding: 70px 0 15px 0;

  @include desktop{
    //width: 50%;
    flex-shrink: 0;
    flex-grow: 1;

    background: $primary-gradient-vertical;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 50%;
    padding: 0;

  }

  img,svg{
    max-height: 150px;
    max-width: 90%;
    @include noDragging;
    @include noSelect;

    @include tablet{
      max-height: 300px;
    }

    @include desktop{
      max-height: 400px;
    }

    @include huge{
      max-height: 500px;
    }
  }
}

.auth{
  text-align: center;
  min-width: 100%;
  padding: 0 20px;
  //max-width: 600px;

  @include desktop{
    min-width: 0;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    overflow-y: auto;
    text-align: left;


    .formWrapper {
      flex-grow: 1;
      max-width: 500px;
      min-width: 300px;
    }

  }
}

