@import "assets/scss/common";

.root {
  min-height: 100vh;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .title {
    font-size: 8rem;
    font-weight: bold;
  }
  .subtitle {
    font-weight: bold;
    font-size: 1.8rem;
    color: $error;
  }
  .text{
    text-align: center;
    padding-bottom: 22px;
  }

}

.component{
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: lighten($error, 35%);
  color: $error;
  .icon{
    height: 30px;
    svg{
      font-size: 30px;
    }

    padding-right: 6px;
  }
  .text{
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.3;
  }
  .subtext{
    font-size: 1.1rem;
    font-weight: normal;
    a{
      color: $error;
      text-decoration: underline;
    }
  }
}

